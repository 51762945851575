/*
 * File: PatientDetailcard.css
 * Project: mint-portal
 * File Created: Monday, 3rd October 2022 1:00:25 pm
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Thursday, 9th March 2023 8:16:40 am
 * Modified By: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */

.patient-chip {
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  gap: 10px;
  padding: 6px 18px;
  border-radius: 8px;
}

.info-flex-10 {
  display: flex;
  gap: 10px;
  align-items: center;
}
.info-flex-30 {
  display: flex;
  gap: 10px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.info-flex-20 {
  display: flex;
  gap: 20px;
  align-items: center;
}
.info-flex-50 {
  display: flex;
  gap: 50px;
  align-items: center;
  margin-top: 30px;
}

.info-flex-row {
  display: flex;
  flex-direction:row;
}

.info-gap {
  gap: 7.93rem;
}
.infocard-top {
  padding: 25px 32px 20px 30px;
}

/*
 * File: ViewNppwrk.css
 * Project: mint-portal
 * File Created: Tuesday, 4th October 2022 4:44:07 pm
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Tuesday, 4th October 2022 4:46:23 pm
 * Modified By: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
.nppwHeader {
  margin: 18px 0px 0px 8px;
}

.nppwTitle {
  flex-grow: 1;
  letter-spacing: 0.44px;
}

.nppwDetailBox {
  width: 76.5vw;
  height: 65vh;
  margin: 32px 0px 32px 24px;
  padding-right: 11px;
}

.nppwDetail {
  margin: 32px 0px 0px 24px;
}

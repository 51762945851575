/*
 * File: Services.css
 * Project: mint-portal
 * File Created: Monday, 29th August 2022 2:06:07 pm
 * Author: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Last Modified: Monday, 29th August 2022 2:13:00 pm
 * Modified By: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
.selected-card {
  /* width: 200px; */
  /* height: 85px; */
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-radius: 8px;
  /* border: solid 1px var(--md-ref-neutral-90);
  background-color: var(--md-ref-neutral-100); */
}

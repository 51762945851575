/*
 * File: Links.css
 * Project: mint-portal
 * File Created: Tuesday, 30th May 2023 6:04:46 pm
 * Author: Priya Gupta (priyagupta.90@gmail.com)
 * -----
 * Last Modified: Tuesday, 30th May 2023 9:10:50 pm
 * Modified By: Priya Gupta (priyagupta.90@gmail.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */

 .linkContainer {
    display: flex;
    flex-direction: column;
  }
  
  .linkHeader {
    margin: 24px 0px 0px 8px;
  }
  
  .linkTitle {
    flex-grow: 1;
    letter-spacing: 0.44px;
  }

/*
 * File: AddOtherLinks.css
 * Project: mint-portal
 * File Created: Tuesday, 30th May 2023 10:48:24 pm
 * Author: Priya Gupta (priyagupta.90@gmail.com)
 * -----
 * Last Modified: Monday, 5th June 2023 7:51:18 pm
 * Modified By: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Copyright 2020 - 2023 Mutual Mobile, Mutual Mobile
 */
.link_Header {
    margin: 18px 0px 0px 8px;
}

.link_Title {
    flex-grow: 1;
    letter-spacing: 0.44px;
}

.otherLinkCard {
    margin: 32px 0px 32px 24px;
    width: 76.5vw;
    padding: 32px 0 41px 32px;
}

.otherLinkDiv {
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.otherLinkProfile {
    position: relative;
    width: 21%;
}

.otherLinkImageClass {
    width: 91px !important;
    height: 91.5px !important;
    border-radius: 50px;
}

.otherLinkIconClass {
    position: absolute;
    width: 32.6px;
    height: 32.8px;
    border-radius: 50px !important;
    top: 60px !important;
    left: 70px;
    padding-left: 7px;
    padding-top: 7px;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15);
    cursor: pointer;
}

.otherLinkCameraIcon {
    width: 20px !important;
    height: 18px !important;
}

.imageText {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
}
/*
 * File: OngoingBlockout.css
 * Project: mint-portal
 * File Created: Wednesday, 14th September 2022 3:16:31 am
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Wednesday, 14th September 2022 3:16:50 am
 * Modified By: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
.ongoing {
  display: flex;
  flex-direction: column;
}

/*
 * File: ListStaff.css
 * Project: mint-portal
 * File Created: Monday, 4th July 2022 2:29:43 pm
 * Author: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Last Modified: Tuesday, 9th August 2022 5:35:13 pm
 * Modified By: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
.containerdiv {
  width: 76.5vw;
  /* flex-grow: 0; */
  margin: 32px 0px 32px 32px;
  /* border-radius: 16px; */
  padding-right: 11px;
  padding-left: 11px;
}

.userName {
  height: 24px;
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.avatar {
  border-radius: 50%;
  width: 24px;
}

.pagination {
  position: absolute;
  right: 32px;
}

/*
 * File: UpdateSocialLinks.css
 * Project: mint-portal
 * File Created: Tuesday, 30th May 2023 8:20:57 pm
 * Author: Priya Gupta (priyagupta.90@gmail.com)
 * -----
 * Last Modified: Wednesday, 7th June 2023 10:01:37 am
 * Modified By: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Copyright 2020 - 2023 Mutual Mobile, Mutual Mobile
 */

 .updateSocialLinkCard {
    margin-top: 32px;
    padding: 32px 0 41px 32px;
}

.updateSocialLinkDiv {
    display: flex;
    flex-direction: column;
    gap: 32px;
}
 .updateSocialLinkProfile {
    position: relative;
    width: 21%;
}

.updateSocialLinkImageClass {
    width: 91px !important;
    height: 91.5px !important;
    border-radius: 50px;
}

.updateSocialLinkIconClass {
    position: absolute;
    width: 32.6px;
    height: 32.8px;
    border-radius: 50px !important;
    top: 60px !important;
    left: 70px;
    padding-left: 7px;
    padding-top: 7px;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15);
    cursor: pointer;
}

.updateSocialLinkCameraIcon {
    width: 20px !important;
    height: 18px !important;
}

.imageText {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
}
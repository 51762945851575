/*
 * File: RewardDetails.css
 * Project: mint-portal
 * File Created: Tuesday, 1st November 2022 12:30:19 pm
 * Author: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Last Modified: Tuesday, 1st November 2022 12:31:36 pm
 * Modified By: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
.deleteBox {
    width: 36px;
    height: 36px;
}
.edit-reward-details{
    width: 10rem;
    height: 36px;
}
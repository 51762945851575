/*
 * File: Booking.css
 * Project: mint-portal
 * File Created: Tuesday, 13th September 2022 2:36:18 am
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Friday, 16th September 2022 5:59:19 pm
 * Modified By: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
.booking {
  display: flex;
  flex-direction: column;
}

.bookingHeader {
  margin: 18px 0px 0px 8px;
}

.bookingTitle {
  flex-grow: 1;
  letter-spacing: 0.44px;
}

.bookingStatusCard {
  display: flex;
  flex-direction: row;
  margin: 8px 16px;
  gap: 682px;
}

.customTab {
  margin-left: 16px;
  width: 76.5vw;
}

.box {
  z-index: 100;
  position: relative;
}
.app-bar {
  position: fixed;
  box-shadow: 0 1px 3px 1px rgba(24, 27, 24, 0.1), 0 1px 2px 0 rgba(24, 27, 24, 0.25) !important;
}
.toolbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.logo {
  width: 90.7px;
  height: 30px;
}
.rightContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-grow: 0;
  align-items: center;
  padding: 0;
}
.help-icon {
  width: 28px !important;
  height: 28px !important;
  cursor: pointer;
}
.profile-container {
  flex-grow: 0;
  flex-direction: row;
  display: flex;
  align-items: center;
  padding: 6px 32px 6px 24px;
}
.profile-img {
  width: 40px;
  height: 40px;
  border-radius: 64px;
  padding: 8px;
}
.profile-link {
  text-decoration: none;
  cursor: pointer;
  text-align: left;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  gap: 3px;
}
.menu-list {
  align-self: stretch;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}

.menu-item-setting {
  margin-right: 34px;
  height: 19.2px;
  width: 18.7;
}
.menu-item-logout {
  width: 20px;
  height: 18px;
  flex-grow: 0;
  margin-right: 34px;
}

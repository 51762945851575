/*
 * File: DetailDsoBlockout.css
 * Project: mint-portal
 * File Created: Monday, 3rd October 2022 5:06:57 pm
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Monday, 3rd October 2022 5:29:04 pm
 * Modified By: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
.blockoutDetailContainer {
  display: flex;
  flex-direction: column;
  width: 76.5vw;
}

.blockoutDetailHeader {
  margin: 32px -10px 0px 0px;
}

.blockoutDetailBox {
  width: 76.5vw;
  height: 65vh;
  margin: 32px 0px 32px 24px;
  padding-right: 11px;
}

.blockoutDetail {
  margin: 32px 0px 0px 24px;
}

.deleteBox {
  width: 36px;
  height: 36px;
}

.deleteIcon {
  width: 24px;
  height: 24px;
}

/*
 * File: Faq.css
 * Project: mint-portal
 * File Created: Saturday, 10th December 2022 10:30:17 am
 * Author: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Last Modified: Saturday, 10th December 2022 10:30:18 am
 * Modified By: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
.faqContainer{
    display: flex;
    flex-direction: column;
} 
.faqHeader {
    margin: 18px 0px 0px 8px;
}
  
.faqTitle {
    flex-grow: 1;
    letter-spacing: 0.44px;
}
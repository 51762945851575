.landingPage {
  height: 100vh;
  background: linear-gradient(to bottom, #fcfdfc 0%, #fcfdfc 40%, #f2f3f2 0%, #f2f3f2 100%);
  flex-grow: 0;
  width: 100vw;
  position: fixed;
}

.headerContent {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  margin: 40px;
}

.logo {
  width: 90.7px;
  height: 30px;
}

.poweredBy {
  text-decoration: underline;
}

.mint {
  text-decoration: underline;
}

.copyRight {
  text-decoration: none;
  width: 12px;
  height: 16px;
  position: absolute;
  align-self: flex-end;
  top: 4%;
}

.footerContent {
  flex-grow: 0;
  width: 369px;
  height: 34px;
  display: inline-block;
  margin: 0px 536px 0 535px;
}
/*
 * File: CustomProgressBar.css
 * Project: mint-portal
 * File Created: Monday, 21st August 2023 7:31:10 am
 * Author: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Last Modified: Tuesday, 22nd August 2023 7:41:29 am
 * Modified By: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Copyright 2020 - 2023 Mutual Mobile, Mutual Mobile
 */

 .progressBarContainer {
  height: 24px;
  position: relative;
  border-radius: 4px;
  overflow: hidden; 
  /* width: 500px; */
  border:'2px solid red';
  margin: 0px 10px 30px 0px;
}

 .progressBar {
  width: 100%;
  height: 100%;
  position: relative;
}

.progressSegment {
  height: 100%;
  position: absolute;
}


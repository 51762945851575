/*
 * File: MapOperatories.css
 * Project: mint-portal
 * File Created: Monday, 29th August 2022 8:57:44 pm
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Tuesday, 29th November 2022 10:48:03 pm
 * Modified By: Deepankar Saini (deepankar.saini@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
.map-operatories-details-container {
  padding: 36px 24px;
}
.Card {
  width: 672px;
  /* height: 107px; */
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 0;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
}
.Card-header {
  /* height: 24px; */
  flex-grow: 1;
  letter-spacing: 0.4px;
  text-align: left;
}

.CardContent {
  /* height: 51px; */
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 16px;
}

html
{
  scroll-behavior: smooth;
}
/*
 * File: VieClinic.css
 * Project: mint-portal
 * File Created: Monday, 12th September 2022 10:40:53 pm
 * Author: Jackson Thounaojam (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Thursday, 15th December 2022 2:53:00 pm
 * Modified By: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
.view-clinic-container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 76.5vw;
  margin: 32px 0px 32px 24px;
}
.view-clinic-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 25px;
}
.view-clinic-carousel .react-multi-carousel-list {
  width: 480px;
  height: 250px;
  border-radius: 16px;
}
.react-multiple-carousel__arrow {
  z-index: 1;
}
.view-clinic-carousel .react-multiple-carousel__arrow,
.view-clinic-carousel .react-multiple-carousel__arrow:hover {
  background-color: rgb(0, 0, 0, 0.4);
}
.view-clinic-carousel .react-multiple-carousel__arrow::before {
  color: #fff !important;
  font-size: 14px !important;
}
.view-clinic-carousel .react-multi-carousel-dot-list {
  top: 13.5rem !important;
}

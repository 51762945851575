/*
 * File: ResetPassword.css
 * Project: mint-portal
 * File Created: Thursday, 4th August 2022 5:57:12 pm
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Friday, 12th August 2022 10:17:21 pm
 * Modified By: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
.container {
  height: 577px;
  margin: 24px 188px 46px 368px;
  padding: 48px 40px 0px 40px;
  box-shadow: 0 1px 3px 1px rgba(24, 27, 24, 0.1), 0 1px 2px 0 rgba(24, 27, 24, 0.25) !important;
}

.sub-header {
  padding-top: 8px;
  line-height: normal !important;
  letter-spacing: 0.4px !important;
}

.grid-box-container {
  margin: 0px 68px 30px 0px;
  display: flex;
  flex-direction: row;
  gap: 24px;
}

.btn-container {
  margin-top: 32px;
  margin-left: 36%;
}

/* for removing arrow buttons in number field */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

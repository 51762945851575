.faqCard {
  margin: 32px 0px 32px 24px;
  width: 76.5vw;
  padding: 32px 0 41px 32px;
  
}

.faqDiv
{
    display: flex;
    flex-direction: column;
    gap: 32px;
} 
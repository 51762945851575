/*
 * File: Loader.css
 * Project: mint-portal
 * File Created: Monday; 25th July 2022 9:22:32 pm
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Tuesday, 14th March 2023 11:35:53 am
 * Modified By: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile; Mutual Mobile
 */
.loaderContainer {
  width: 100%;
  height: 300vh;
  opacity: 0.5;
  position: absolute;
  z-index: 1;
}

.loader {
  width: 74.6px !important;
  height: 80px !important;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
}

.loadersmall {
  width: 44.6px !important;
  height: 50px !important;
  position: absolute;
  z-index: 1;
  top: 40%;
  left: 45%;
}
.margin {
  margin-left: 20% !important;
  margin-top: 10% !important;
}

/*
 * File: InsuranceDetail.css
 * Project: mint-portal
 * File Created: Thursday, 28th July 2022 1:41:33 pm
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Monday, 1st August 2022 11:39:52 pm
 * Modified By: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
 .insuranceDetailContainer {
    display: flex;
    flex-direction: column;
    width: 76.5vw;
}

.insuranceDetailHeader {
    margin: 32px -10px 0px 0px;
}

.insuranceDetailBox {
    width: 76.5vw;
    height: 65vh;
    margin: 32px 0px 32px 24px;
    padding-right: 11px;
}

.insuranceDetail {
    margin: 32px 0px 0px 24px;
}

.listItems {
    display: flex;
    flex-direction: row;
    height: 18px;
    margin-bottom: 10px;
}

.deleteBox{
    width: 36px;
    height: 36px
}

.deleteIcon{
    width: 24px;
    height: 24px;
}

.clinicBox{
   display: flex;
   flex-direction: column;
   margin-top: 32px;
}

.dialogContext{
    display: flex;
    flex-direction: column; 
    gap: 16px; 
}

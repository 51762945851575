/*
 * File: patientDetails.css
 * Project: mint-portal
 * File Created: Thursday, 29th September 2022 4:40:47 pm
 * Author: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Last Modified: Wednesday, 6th September 2023 6:51:40 am
 * Modified By: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
.patient-detail-container{
    display: flex;
    flex-direction: column;
    gap:30px;
    width:100%;
    margin: 40px auto auto 2%;
    margin-top: 40px;
}

.patient-detail-interior{
    display: flex;
    flex-direction: column;
    gap:23px;
}

.flex-row-btwn{
    display: flex;
    justify-content: space-between
}

.deleteBox{
    width: 36px;
    height: 36px
}
/*
 * File: InsuranceDetails.css
 * Project: mint-portal
 * File Created: Wednesday, 29th March 2023 9:09:50 am
 * Author: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Last Modified: Wednesday, 29th March 2023 9:19:18 am
 * Modified By: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Copyright 2020 - 2023 Mutual Mobile, Mutual Mobile
 */
 .insuranceContainer {
    display: flex;
    flex-direction: column;
    width: 76.5vw;
}

.insuranceDetailHeader {
    margin: 32px 0px 0px 0px;
}

.insuranceTitle {
    flex-grow: 1;
    letter-spacing: 0.44px;
  }
  
.deleteButton {
    width: 42px;
    height: 42px;
}

.editDetailButton {
    width: 146px;
    flex-grow: 0;
    padding: 8px 22px;
    height: 34px;
}

.insuranceDetailsBox {
    width: 76.5vw;
    flex-grow: 0;
    margin: 32px 32px 32px 24px;
    border-radius: 16px;
    border:'1px solid red';
}

.insuranceDetail {
    margin: 32px 0px 0px 24px;
    gap: 32px;
    position: inherit;
    border:'1px solid red';
}
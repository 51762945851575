/*
 * File: DetailAutoRewards.css
 * Project: mint-portal
 * File Created: Monday, 12th June 2023 09:15:48 am
 * Author: Rashi Bhatt (rashi.bhatt@mutualmobile.com)
 * -----
 * Last Modified: Monday, 12th June 2023 2:44:57 pm
 * Modified By: Rashi Bhatt (rashi.bhatt@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
 .detailAutoRewardsContainer {
    display: flex;
    flex-direction: column;
    width: 76.5vw;
  }
  
.detailAutoRewardsHeader {
    margin: 32px -10px 0px 0px;
  }
  
.detailAutoRewardsBox {
    width: 76.5vw;
    margin: 32px 0px 32px 24px;
    padding-right: 11px;
  }
  
.detailAutoRewards {
    margin: 32px 0px 32px 24px;
    display: flex;
    flex-direction: column;
    gap: 32px;
}
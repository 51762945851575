/*
 * File: ViewSocialLinks.css
 * Project: mint-portal
 * File Created: Tuesday, 30th May 2023 8:20:28 pm
 * Author: Priya Gupta (priyagupta.90@gmail.com)
 * -----
 * Last Modified: Wednesday, 31st May 2023 12:48:12 am
 * Modified By: Priya Gupta (priyagupta.90@gmail.com)
 * -----
 * Copyright 2020 - 2023 Mutual Mobile, Mutual Mobile
 */
 .avatar {
    border-radius: 50%;
    width: 24px;
  }

  .linkTitle {
    height: 24px;
    display: flex;
    flex-direction: row;
    gap: 16px;
  }

  .containerLinkdiv {
    margin: 8px 0px 24px -8px;
    padding-right: 11px;
    padding-left: 11px;
    width: 76.5vw;
  }

  .actionText {
    height: 20px;
    flex-grow: 0;
    font-style: normal;
    letter-spacing: 0.32px;
    cursor: pointer;
  }
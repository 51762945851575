/*
 * File: UpdatePatientProfile.css
 * Project: mint-portal
 * File Created: Thursday, 10th August 2023 7:46:27 pm
 * Author: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Last Modified: Monday, 25th September 2023 7:00:28 am
 * Modified By: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Copyright 2020 - 2023 Mutual Mobile, Mutual Mobile
 */

.nameField{
    display: flex;
    gap: 8px;
}
.phoneNumber{
    position: relative;
    display: flex;
    /* align-items: center; */
 }
.emailField{
    display: flex;
    flex-direction: column;
}
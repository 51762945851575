/*
 * File: RedeemReward.css
 * Project: mint-portal
 * File Created: Thursday, 27th October 2022 9:47:57 pm
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Friday, 28th October 2022 12:27:44 am
 * Modified By: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
.selected-patient {
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  margin: 0px 0px 0px 2px;
  border: 1px solid transparent;
  padding: 4px 12px 4px 8px;
}

.redeemAddContainer {
  display: flex;
  flex-direction: column;
  width: 76.5vw;
}

.redeemAddHeader {
  margin: 32px -24px 0px 0px;
}

.redeemAddBox {
  width: 76.5vw;
  min-height: 65vh;
  margin: 32px 0px 0px 24px;
  padding-right: 11px;
}

.redeemAdd {
  margin: 32px 0px 18px 24px;
}

.redeemSection {
  display: flex;
  flex-direction: column;
  width: 40vw;
  gap: 40px;
}

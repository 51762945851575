/*
 * File: NavCard.css
 * Project: mint-portal
 * File Created: Thursday, 18th August 2022 12:23:09 pm
 * Author: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Last Modified: Thursday, 18th August 2022 6:22:31 pm
 * Modified By: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
.Card {
  width: 348px;
  /* height: 107px; */
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 0;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
}
.Card-header {
  /* height: 24px; */
  flex-grow: 1;
  letter-spacing: 0.4px;
  text-align: left;
}

.CardContent {
  /* height: 51px; */
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 16px;
}
.selected {
  background-color: rgba(38, 166, 38, 0.06) !important;
}

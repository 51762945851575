/*
 * File: ListNotifications.css
 * Project: mint-portal
 * File Created: Tuesday, 14th February 2023 3:28:44 pm
 * Author: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Last Modified: Wednesday, 15th February 2023 12:46:50 pm
 * Modified By: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Copyright 2020 - 2023 Mutual Mobile, Mutual Mobile
 */
 .notificationContainer {
    margin: 32px 0px 32px 24px;
    padding-right: 11px;
    padding-left: 11px;
    width: 76.5vw;
  }

  .actionText {
    height: 20px;
    flex-grow: 0;
    font-style: normal;
    letter-spacing: 0.32px;
    cursor: pointer;
  }
/*
 * File: DetailService.css
 * Project: mint-portal
 * File Created: Tuesday, 9th August 2022 5:40:15 pm
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Wednesday, 10th August 2022 3:55:23 pm
 * Modified By: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
 .serviceDetailContainer {
    display: flex;
    flex-direction: column;
    width: 76.5vw;
}

.serviceDetailHeader {
    margin: 32px 0px 0px 0px;
}

.serviceTitle {
    flex-grow: 1;
    letter-spacing: 0.44px;
  }
  
.deleteButton {
    width: 42px;
    height: 42px;
}

.editDetailButton {
    width: 146px;
    flex-grow: 0;
    padding: 8px 22px;
    height: 34px;
}

.serviceDetailBox {
    width: 76.5vw;
    flex-grow: 0;
    margin: 32px 32px 32px 24px;
    border-radius: 16px;
}

.serviceDetail {
    margin: 32px 0px 0px 24px;
    gap: 32px;
    position: inherit;
}

.profileImage {
    margin: 36px 0px;
    width: 88.2px;
    height: 88.2px;
    border-radius: 50%;
}

.assignedClinics{
    margin-bottom: 32px;
}


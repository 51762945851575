/*
 * File: DetailConfigureReward.css
 * Project: mint-portal
 * File Created: Friday, 14th October 2022 12:03:47 am
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Friday, 14th October 2022 12:05:04 am
 * Modified By: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
.redeemDetailContainer {
  display: flex;
  flex-direction: column;
  width: 76.5vw;
}

.redeemDetailHeader {
  margin: 32px -10px 0px 0px;
}

.redeemDetailBox {
  width: 76.5vw;
  margin: 32px 0px 32px 24px;
  padding-right: 11px;
}

.redeemDetail {
  margin: 32px 0px 32px 24px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

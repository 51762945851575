/*
 * File: CustomCard.css
 * Project: mint-portal
 * File Created: Tuesday, 13th September 2022 12:18:38 pm
 * Author: Jackson Thounaojam (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Thursday, 16th February 2023 12:04:18 pm
 * Modified By: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
.view-clinic-card {
  width: 100%;
  /* height: 535px; */
  flex-grow: 0;
  /* margin: 0 1px 0 0; */
  /* padding: 20px 32px 40px 24px; */
  border-radius: 16px;
  border-style: solid !important;
  border-width: 1px !important;
}

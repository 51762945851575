/*
 * File: BreadCrumbs.css
 * Project: mint-portal
 * File Created: Monday, 27th June 2022 10:59:46 am
 * Author: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Last Modified: Tuesday, 2nd August 2022 1:59:27 pm
 * Modified By: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */

.breadCrumbs {
  /* margin-left: 24px; */
  text-align: left;
}

/*
 * File: ReferFriend.css
 * Project: mint-portal
 * File Created: Tuesday, 1st November 2022 9:49:40 am
 * Author: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Last Modified: Tuesday, 1st November 2022 9:51:34 am
 * Modified By: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
 .referrerHeader {
    margin: 18px 0px 0px 8px;
  }
  
  .referrerTitle {
    flex-grow: 1;
    letter-spacing: 0.44px;
  }
  
  .referrerDetailBox {
    width: 76.5vw;
    height: 65vh;
    margin: 32px 0px 32px 24px;
    padding-right: 11px;
  }
  
  .referrerDetail {
    margin: 32px 0px 0px 24px;
  }
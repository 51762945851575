/*
 * File: ListDsoBlockout.css
 * Project: mint-portal
 * File Created: Monday, 3rd October 2022 4:45:15 pm
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Monday, 3rd October 2022 4:53:16 pm
 * Modified By: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */

.dsoListHeader {
  margin: 18px 0px 0px 8px;
}

.dsoListTitle {
  flex-grow: 1;
  letter-spacing: 0.44px;
}

.pagination {
  position: absolute;
  right: 32px;
}

.dsoListTable {
  margin: 28px 0px 24px 32px;
  padding-right: 11px;
  padding-left: 11px;
  width: 76.5vw;
}

/*
 * File: FormCard.css
 * Project: mint-portal
 * File Created: Thursday, 18th August 2022 1:12:16 pm
 * Author: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Last Modified: Tuesday, 11th October 2022 8:44:17 pm
 * Modified By: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
.rectangle {
  border-radius: 16px;
  border-style: solid;
  border-width: 1px;
}

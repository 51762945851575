/*
 * File: ListRedeemHistoryRewards.css
 * Project: mint-portal
 * File Created: Friday, 14th October 2022 12:02:56 am
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Friday, 14th October 2022 2:05:08 am
 * Modified By: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
.configureRedeemContainer {
  margin: 8px 0px 24px -4px;
  padding-right: 11px;
  padding-left: 11px;
  width: 76.5vw;
}

.pagination {
  position: absolute;
  right: 32px;
}

/*
 * File: EditReferFriend.css
 * Project: mint-portal
 * File Created: Wednesday, 2nd November 2022 3:03:20 pm
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Wednesday, 2nd November 2022 4:20:11 pm
 * Modified By: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
.referContainer {
  display: flex;
  flex-direction: column;
  width: 76.5vw;
}
.referBox {
  display: flex;
  margin: 32px 0px 32px 24px;
  padding-right: 11px;
  width: 76.5vw;
  flex-direction: column;
  gap: 24px;
  height: 65vh;
}

.serviceAddContainer {
  display: flex;
  flex-direction: column;
  width: 74.5vw;
}

.serviceAddHeader {
  margin: 32px -24px 0px 0px;
}

.serviceAddBox {
  display: flex;
  margin: 32px 0px 32px 24px;
  padding-right: 11px;
  width: 76.5vw;
}

.serviceAdd {
  margin: 32px 0px 0px 24px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.radioButtonSection {
  display: flex;
  flex-direction: column;
  /* margin-top: 32px; */
  margin-left: 8px;
  position: inherit;
}

.textFieldSection {
  display: flex;
  flex-direction: column;
  width: 40vw;
}

.serviceTitle {
  flex-grow: 1;
  letter-spacing: 0.44px;
}

.serviceProfile {
  position: relative;
}

.profileDescription {
  margin-left: 15px !important;
}
.serviceImageClass {
  width: 91.1px !important;
  height: 91.5px !important;
  border-radius: 50px !important;
  margin: 15px 0px 15px 10px !important;
}
.serviceIconClass {
  position: absolute;
  width: 32.6px;
  height: 32.8px;
  border-radius: 50px !important;
  top: 80px !important;
  left: 74px;
  padding-left: 7px;
  padding-top: 7px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15);
  cursor: pointer;
}

.serviceCameraIcon {
  width: 20px !important;
  height: 18px !important;
}

.profileText {
  display: flex;
  flex-direction: column;
  /* margin-bottom: 5px; */
}

/*
 * File: Services.css
 * Project: mint-portal
 * File Created: Thursday, 4th August 2022 8:35:36 pm
 * Author: Paras Kapoor (paras.kapoor@mutualmobile.com)
 * -----
 * Last Modified: Friday, 12th August 2022 1:19:04 pm
 * Modified By: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
.serviceContainer {
  display: flex;
  flex-direction: column;
}

.serviceHeader {
  margin: 18px 0px 0px 8px;
}

.serviceTitle {
  flex-grow: 1;
  letter-spacing: 0.44px;
}

.filterDiv {
  height: 42px;
  width: 42px;
  cursor: pointer;
}

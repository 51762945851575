/*
 * File: Dashboard.css
 * Project: mint-portal
 * File Created: Monday, 14th November 2022 3:39:53 pm
 * Author: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Last Modified: Friday, 30th June 2023 3:24:24 pm
 * Modified By: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
.scroller {
  position: absolute;
  overflow: hidden;
}

.scroller::-webkit-scrollbar {
  display: none;
}

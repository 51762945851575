/*
 * File: CustomPhoneField.css
 * Project: mint-portal
 * File Created: Tuesday, 28th February 2023 12:42:38 pm
 * Author: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Last Modified: Thursday, 14th September 2023 8:56:50 am
 * Modified By: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Copyright 2020 - 2023 Mutual Mobile, Mutual Mobile
 */

input[type='tel']::placeholder {
  font-size: 16px;
}

.label {
  position: absolute;
  pointer-events: none;
  left: 10px;
  top: -4%;
  /* z-index: -10; */
}
input[type='tel']:focus ~ .label {
  display: block !important;
  /* transform: translateY(-65%); */
  transition: 0.5s;
  /* z-index: 10; */
}

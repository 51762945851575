/*
 * File: Insurance.css
 * Project: mint-portal
 * File Created: Tuesday, 26th July 2022 3:56:54 pm
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Tuesday, 26th July 2022 8:25:05 pm
 * Modified By: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
 .insuranceContainer {
    display: flex;
    flex-direction: column;
  }
  
  .insuranceHeader {
    margin: 18px 0px 0px 8px;
  }
  
  .insuranceTitle {
    flex-grow: 1;
    letter-spacing: 0.44px;
  }
  
/*
 * File: LandingImages.css
 * Project: mint-portal
 * File Created: Thursday, 13th October 2022 11:52:20 am
 * Author: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Last Modified: Friday, 9th December 2022 4:45:25 pm
 * Modified By: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
.landing-carousel-container {
  display: flex;
  flex-direction: column;
  gap:32px;
  width: 76.5vw;
  margin: 32px 0px 32px 24px;
}
.landing-carousel-card {
  height: 480px;
  position: relative;
}

.landing-carousel-img {
  width: 142px !important;
  height: 174.2px !important;
}
.landing-images-grid {
  display: grid;
  grid-template-columns: repeat(5, 142px);
  grid-column-gap: 20px;
}

.landing-remove-btn {
  position: absolute;
  z-index: 50;
  top: -5px;
  right: -10px;
  cursor: pointer;
}

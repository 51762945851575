.login .containers {
  width: 704px;
  height: 598px !important;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 30px;
  margin: 0px 188px 46px 368px;
  padding: 64px 56px;
  box-shadow: 0 1px 3px 1px rgba(24, 27, 24, 0.1), 0 1px 2px 0 rgba(24, 27, 24, 0.25)!important;
  position: fixed;
  top: 130px;
}

.login .welcome {
  width: 138px;
  height: 39px;
  flex-grow: 0;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  margin: 0px;
}

.login .welcometext {
  width: 100%;
  height: 54px;
  flex-grow: 0;
  line-height: normal;
  letter-spacing: 0.44px;
  text-align: left;
}

.login .forgotpassword {
  padding: 8px 35px;
  border-radius: 4px;
  position: relative;
  left: 62%;
  cursor: pointer;
}

.version {
  position: relative;
  left: 75%
}

.alignButtons
{
  left: 11px;
}


/*
 * File: ClinicDetails.css
 * Project: mint-portal
 * File Created: Monday, 22nd August 2022 1:15:37 pm
 * Author: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Last Modified: Wednesday, 30th November 2022 3:33:14 pm
 * Modified By: Deepankar Saini (deepankar.saini@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */

.phone-section {
  position: relative;
  display: flex;
  align-items: center;
}

.country-code {
  position: absolute;
  top: 30%;
  z-index: 1;
  left: 2.5%;
}
.clinic-details-container {
  padding: 36px 24px;
}

.upload-btn {
  width: 100%;
  height: 177px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  /* margin: 8px 0 21px; */
  padding: 48px 199px;
  border-radius: 6px;
}

.carousel-img {
  width: 9rem;
  height: 80px;
  flex-grow: 0;
  margin: -6rem 9.2px 0 10px;
  border-radius: 6px;
  position: relative;
}
.close-img {
  position: absolute;
  top: -4.5rem;
  right: 3%;
}
.react-multi-carousel-list {
  height: 160px;
}
.react-multiple-carousel__arrow.react-multiple-carousel__arrow--right,
.react-multiple-carousel__arrow.react-multiple-carousel__arrow--left,
.react-multiple-carousel__arrow.react-multiple-carousel__arrow--right:hover,
.react-multiple-carousel__arrow.react-multiple-carousel__arrow--left:hover {
  top: 7rem;
  position: absolute;
  width: 26px;
  height: 26px;
  min-width: 26px;
  min-height: 26px;
  background-color: #e5e7e5;
}

.react-multiple-carousel__arrow::before {
  color: #000 !important;
  font-size: smaller;
}
/* .react-multiple-carousel__arrow::before {
  color: #000;
} */
/* .react-multiple-carousel__arrow--left::before,
.react-multiple-carousel__arrow--right::before {
  min-width: 5px;
  min-height: 5px;
  width: 5px;
  
} */
.react-multi-carousel-dot-list {
  top: 7rem;
}
li.react-multi-carousel-dot button {
  width: 8px;
  height: 8px;
}

.profileText {
  display: flex;
  flex-direction: column;
  /* margin-bottom: 5px; */
}

html
{
  scroll-behavior: smooth;
}
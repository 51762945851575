/*
 * File: mainLayout.css
 * Project: mint-portal
 * File Created: Friday, 24th June 2022 4:52:19 pm
 * Author: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Last Modified: Wednesday, 19th October 2022 2:26:59 pm
 * Modified By: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */

.grid {
  margin-top: 57px !important;
  padding-left: 0px;
  margin-bottom: 52px;
}

/*
 * File: EmptyState.css
 * Project: mint-portal
 * File Created: Monday, 4th July 2022 3:19:07 pm
 * Author: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Last Modified: Monday, 19th June 2023 6:35:36 pm
 * Modified By: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */

.emptyState {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  /* position: inherit;  */
}

.isForbidden {
  position: relative;
  top: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
}

.image {
  width: 100.6px;
  height: 105.9px;
  align-items: center;
}

.text {
  height: 27px;
  flex-grow: 0;
  letter-spacing: 0.44px;
  text-align: center;
}

.subText {
  height: 19px;
  flex-grow: 0;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  white-space: pre-line;
}

.buttonContainer {
  padding-top: 10px;
}

#addButtonLink {
  text-decoration: none;
  height: 36px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 8px 11px;
  border-radius: 4px;
  text-transform: uppercase;
}

.addContainer {
  position: relative;
  cursor: pointer;
}

.inputButton {
  position: absolute;
  opacity: 0;
}
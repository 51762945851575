/*
 * File: EditNppwrk.css
 * Project: mint-portal
 * File Created: Tuesday, 4th October 2022 5:46:58 pm
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Tuesday, 4th October 2022 7:34:34 pm
 * Modified By: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
.nppwContainer {
  display: flex;
  flex-direction: column;
  width: 76.5vw;
}
.nppwBox {
  display: flex;
  margin: 32px 0px 32px 24px;
  padding-right: 11px;
  width: 76.5vw;
  flex-direction: column;
  gap: 24px;
  height: 65vh;
}

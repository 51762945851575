/*
 * File: ElectiveProcedure.css
 * Project: mint-portal
 * File Created: Monday, 12th June 2023 2:21:56 pm
 * Author: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Last Modified: Monday, 12th June 2023 3:27:34 pm
 * Modified By: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Copyright 2020 - 2023 Mutual Mobile, Mutual Mobile
 */
.procedureAddContainer {
  display: flex;
  flex-direction: column;
  width: 74.5vw;
}
.procedureAddHeader {
  margin: 32px -24px 0px 0px;
}

.procedureAddBox {
  display: flex;
  margin: 32px 0px 32px 24px;
  padding: 32px;
  width: 100%;
}

.procedureAdd {
  margin: 32px 0px 0px 24px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.procedureTitle {
  flex-grow: 1;
  letter-spacing: 0.44px;
}

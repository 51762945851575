/*
 * File: DetailStaff.css
 * Project: mint-portal
 * File Created: Thursday, 21st July 2022 3:38:50 pm
 * Author: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Last Modified: Tuesday, 9th August 2022 2:56:15 pm
 * Modified By: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
.staffDetailContainer {
    display: flex;
    flex-direction: column;
    width: 78vw;
}

.staffDetailHeader {
    margin: 32px 0px 0px 0px;
}

.staffTitle {
    flex-grow: 1;
    letter-spacing: 0.44px;
  }
  
.deleteButton {
    width: 42px;
    height: 42px;
}

.editDetailButton {
    width: 146px;
    flex-grow: 0;
    padding: 8px 22px;
    height: 34px;
}

.staffDetailBox {
    width: 77vw;
    flex-grow: 0;
    margin: 32px 32px 32px 24px;
    border-radius: 16px;
    padding-right: 11px;
    padding-left: 11px;
}

.staffDetail {
    margin: 32px 0px 0px 24px;
    gap: 32px;
    position: inherit;
}

.profileImage {
    margin: 32px 0px;
    width: 88.2px;
    height: 88.2px;
    border-radius: 50%;
}

.subHeading {
    display: flex;
    flex-direction: row;
    margin-bottom: 22px;
}

.invitaionFooter{
    display: flex;
    flex-direction: row;
    margin: 4px 4px 4px 0px;
}
.invitationPending{
    margin: 2px 4px 0px 0px;
}
.detailsHeading {
    margin: 4px 4px 4px 0px;
}

.userRole {
    margin-bottom: 32px;
}
.assignedClinics{
    margin-bottom: 32px;
}

.listItem {
    display: flex;
    flex-direction: row;
    gap: 8px;
    height: 18px;
    margin-bottom: 10px;
}

.toggle {
    margin-top: 32px;
    height: 38px;
    padding-right: 32px;

}

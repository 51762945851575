/*
 * File: ServicesIconLibrary.css
 * Project: mint-portal
 * File Created: Wednesday, 17th May 2023 1:35:13 pm
 * Author: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Last Modified: Monday, 10th July 2023 8:29:42 am
 * Modified By: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Copyright 2020 - 2023 Mutual Mobile, Mutual Mobile
 */
.serviceIconContainer {
    display: flex;
    flex-direction: column;
}

.serviceIconHeader {
    margin: 18px 0px 0px 8px;
}

.serviceIconTitle {
    flex-grow: 1;
    letter-spacing: 0.44px;
}

.addButtonContainer {
    position: relative;
    cursor: pointer;
}

.addButtonstyle {
    display: 'inline-block';
    cursor: 'pointer';
    pointer-events: 'auto';
}

/*
 * File: AddDsoBlcokout.css
 * Project: mint-portal
 * File Created: Monday, 3rd October 2022 9:11:01 pm
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Monday, 3rd October 2022 9:36:07 pm
 * Modified By: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
.dsoBlockoutAddContainer {
  display: flex;
  flex-direction: column;
  width: 76.5vw;
}

.dsoBlockoutAddHeader {
  margin: 32px -24px 0px 0px;
}

.dsoBlockoutAddBox {
  display: flex;
  margin: 32px 0px 32px 24px;
  padding-right: 11px;
  width: 76.5vw;
}

.dsoBlockoutAdd {
  margin: 32px 0px 0px 24px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.dsoBlockoutSection {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.dsoBlockoutTitle {
  flex-grow: 1;
  letter-spacing: 0.44px;
}

/*
 * File: AddVideo.css
 * Project: mint-portal
 * File Created: Tuesday, 30th May 2023 9:22:17 am
 * Author: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Last Modified: Tuesday, 30th May 2023 9:27:23 am
 * Modified By: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Copyright 2020 - 2023 Mutual Mobile, Mutual Mobile
 */

.videoHeader {
    margin: 18px 0px 0px 8px;
}
  
.videoTitle {
    flex-grow: 1;
    letter-spacing: 0.44px;
}
 .videoCard {
     margin: 32px 0px 32px 24px;
     width: 76.5vw;
     padding: 32px 0 41px 32px;
 }

 .videoDiv {
     display: flex;
     flex-direction: column;
     gap: 32px;
 }
/*
 * File: ListService.css
 * Project: mint-portal
 * File Created: Thursday, 4th August 2022 6:42:13 pm
 * Author: Paras Kapoor (paras.kapoor@mutualmobile.com)
 * -----
 * Last Modified: Tuesday, 9th August 2022 5:34:54 pm
 * Modified By: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
.containerdiv {
  margin: 28px 0px 24px 32px;
  padding-right: 11px;
  padding-left: 11px;
  width: 76.5vw;
}

.pagination {
  position: absolute;
  right: 32px;
}

.serviceName {
  height: 24px;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.avatar {
  border-radius: 50%;
  width: 24px;
}

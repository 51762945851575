/*
 * File: DetailConfigureReward.css
 * Project: mint-portal
 * File Created: Friday, 14th October 2022 12:02:04 am
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Wednesday, 26th October 2022 2:41:10 am
 * Modified By: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
.rewardDetailContainer {
  display: flex;
  flex-direction: column;
  width: 76.5vw;
}

.rewardDetailHeader {
  margin: 32px -10px 0px 0px;
}

.rewardDetailBox {
  width: 76.5vw;
  margin: 32px 0px 32px 24px;
  padding-right: 11px;
}

.rewardDetail {
  margin: 32px 0px 32px 24px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.deleteBox {
  width: 36px;
  height: 36px;
}

.deleteIcon {
  width: 24px;
  height: 24px;
}

.reward-carousel .react-multi-carousel-list {
  width: 480px;
  height: 250px;
  border-radius: 16px;
}
.react-multiple-carousel__arrow {
  z-index: 1;
}
.reward-carousel .react-multiple-carousel__arrow,
.reward-carousel .react-multiple-carousel__arrow:hover {
  background-color: rgb(0, 0, 0, 0.4);
}
.reward-carousel .react-multiple-carousel__arrow::before {
  color: #fff !important;
  font-size: 14px !important;
}
.reward-carousel .react-multi-carousel-dot-list {
  top: 13.5rem !important;
}

/*
 * File: ListFaq.css
 * Project: mint-portal
 * File Created: Saturday, 10th December 2022 10:31:45 am
 * Author: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Last Modified: Thursday, 23rd March 2023 1:16:44 pm
 * Modified By: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */

.containerdivfaq {
  margin: 32px 0px 32px 24px;
  padding-right: 11px;
  padding-left: 11px;
  width: 76.5vw;
}

@media screen and (min-width: 900px) {
  .drag-row1 {
    width: 11.2%;
  }
  .drag-row2 {
    width: 49.5%;
  }
  .drag-row3 {
    width: 26.5rem;
  }
}
@media screen and (min-width: 1450px) {
  .drag-row1 {
    width: 11.3%;
  }
  .drag-row2 {
    width: 50.1%;
  }
  .drag-row3 {
    width: 26.5rem;
  }
}

@media screen and (min-width: 1600px) {
  .drag-row1 {
    width: 11.5%;
  }
  .drag-row2 {
    width: 52.4%;
  }
  .drag-row3 {
    width: 31rem;
  }
}
@media screen and (min-width: 1700px) {
  .drag-row1 {
    width: 12.2%;
  }
  .drag-row2 {
    width: 53.1%;
  }
  .drag-row3 {
    width: 33rem;
  }
}
@media screen and (min-width: 2500px) {
  .drag-row1 {
    width: 14%;
  }
  .drag-row2 {
    width: 59.4%;
  }
  .drag-row3 {
    width: 50rem;
  }
}

/*
 * File: Providers.css
 * Project: mint-portal
 * File Created: Wednesday, 7th September 2022 4:05:55 pm
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Thursday, 22nd September 2022 1:31:34 am
 * Modified By: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
.provider-container {
  padding: 36px 24px;
}

.provider-empty-state {
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  align-items: center;
  height: 712px;
}

/*
 * File: ListOpenDentalBlockouts.css
 * Project: mint-portal
 * File Created: Thursday, 15th September 2022 1:40:08 am
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Thursday, 15th September 2022 4:15:46 pm
 * Modified By: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
.openDentalStatus {
  width: 122px;
  height: 31px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.67;
  border-radius: 8px;
}

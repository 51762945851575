/*
 * File: OpenDentalConfig.css
 * Project: mint-portal
 * File Created: Tuesday, 23rd August 2022 11:36:04 pm
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Wednesday, 24th August 2022 12:43:37 am
 * Modified By: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
.open-dental-container {
  padding: 36px 24px;
}
.open-dental-container .main-section {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 28px;
}
.api-key-section {
  display: flex;
  align-items: stretch;
  border-radius: 8px;
  flex-grow: 0;
  padding: 20px;
  margin-top: 20px;
}
.api-key-view {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 66.6%;
}
.api-key {
  display: flex;
  gap: 10px;
  align-items: center;
}
.api-key-regenerate {
  display: flex;
  align-items: center;
  width: 33.4%;
  justify-content: flex-end;
}

/*
 * File: Staff.css
 * Project: mint-portal
 * File Created: Thursday, 23rd June 2022 8:22:37 am
 * Author: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Last Modified: Monday, 26th September 2022 11:24:36 pm
 * Modified By: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */

.staffContainer {
  display: flex;
  flex-direction: column;
}

.staffHeader {
  margin: 18px 0px 0px 8px;
}

.staffTitle {
  flex-grow: 1;
  letter-spacing: 0.44px;
}

.filterDiv {
  height: 42px;
  width: 42px;
  cursor: pointer;
}

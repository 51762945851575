/*
 * File: AddBlockout.css
 * Project: mint-portal
 * File Created: Friday, 16th September 2022 2:56:58 pm
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Friday, 16th September 2022 5:12:40 pm
 * Modified By: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
.blockoutAddContainer {
  display: flex;
  flex-direction: column;
  width: 76.5vw;
}

.blockoutAddHeader {
  margin: 32px -24px 0px 0px;
}

.blockoutAddBox {
  display: flex;
  margin: 32px 0px 32px 24px;
  padding-right: 11px;
  width: 76.5vw;
}

.blockoutAdd {
  margin: 32px 0px 0px 24px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.blockoutSection {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.blockoutTitle {
  flex-grow: 1;
  letter-spacing: 0.44px;
}

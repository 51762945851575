/*
 * File: LegalDocuments.css
 * Project: mint-portal
 * File Created: Thursday, 13th October 2022 2:44:10 pm
 * Author: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Last Modified: Wednesday, 19th October 2022 10:26:56 pm
 * Modified By: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
 
 .documentsContainer {
    display: flex;
    flex-direction: column;
    width: 76.5vw;
}

.documentsHeader {
    margin: 32px -10px 0px 0px;
}

.documentsTitle {
    flex-grow: 1;
    letter-spacing: 0.44px;
}

.docContainer {
    width: 76.5vw;
    flex-grow: 0;
    margin: 32px 0px 32px 24px;
    padding: 0px 11px;
}

.userName {
    height: 24px;
    display: flex;
    flex-direction: row;
    gap: 10px;
}
 
.avatar {
    border-radius: 50%;
    width: 24px;
}
.file-inputs {
    position: relative;
    cursor: pointer;
}

.file-inputs input {
        position: relative;
        text-align: right;
        opacity: 0;
        z-index: 2;
        cursor: pointer;
        height: 34px;
        max-width: 108px;
    }
    
.file-inputs  button {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 108px;
        height: 34px;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.1rem;
        cursor: pointer;
        border-radius: 4px;
        border: none;
        outline: none;
}

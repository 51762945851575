/*
 * File: AppointmentNotifications.css
 * Project: mint-portal
 * File Created: Wednesday, 15th February 2023 3:20:26 pm
 * Author: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Last Modified: Wednesday, 15th February 2023 3:21:49 pm
 * Modified By: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Copyright 2020 - 2023 Mutual Mobile, Mutual Mobile
 */
 .notificationScheduleContainer{
    display: flex;
    flex-direction: column;
} 
.notificationScheduleHeader {
    margin: 18px 0px 0px 8px;
}
  
.notificationScheduleTitle {
    flex-grow: 1;
    letter-spacing: 0.44px;
}
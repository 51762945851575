.SideBar {
  height: 100vh;
  position: fixed;
  z-index: 1;
}
body {
  margin: 0;
  padding: 0;
}

.side {
  height: 100%;
  border-width: 1px;
  border-style: solid;
  padding-top: 24px;
  padding-bottom: 480px;
}

.sideRow {
  list-style-type: none;
}

.sideBarList .row {
  margin: 0%;
  text-decoration: none;
  gap: 12px;
  padding-top: 18px;
  padding-bottom: 18px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
}

.row .icon {
  width: 16.5px;
  height: 14px;
  margin: 0px 12px 10px 0px;
}

.row .title {
  width: 203px;
  height: 20px;
  flex-grow: 0;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.32px;
}

.listContainer {
  height: 56px;
  flex-grow: 0;
}

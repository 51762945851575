/*
 * File: Manage.css
 * Project: mint-portal
 * File Created: Thursday, 29th September 2022 4:01:53 pm
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Thursday, 29th September 2022 5:52:25 pm
 * Modified By: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
.manageTitle {
  flex-grow: 1;
  letter-spacing: 0.44px;
}
.manageHeader {
  margin: 18px 0px 0px 8px;
}

.manageSection {
  display: flex;
  flex-direction: row;
  justify-content: start;
  gap: 24px;
  margin: 28px 0px 0px 32px;
}

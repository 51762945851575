/*
 * File: Clinics.css
 * Project: mint-portal
 * File Created: Thursday, 18th August 2022 7:14:34 am
 * Author: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Last Modified: Thursday, 15th December 2022 2:47:43 pm
 * Modified By: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
.clinicContainer {
  display: flex;
  flex-direction: column;
}

.clinicHeader {
  margin: 18px 0px 0px 8px;
}

.clinicTitle {
  flex-grow: 1;
  letter-spacing: 0.44px;
}

.iconButton {
  height: 42px;
  width: 42px;
  cursor: pointer;
  border-radius: 4px;
}

/*
 * File: SideDrawer.css
 * Project: mint-portal
 * File Created: Thursday, 4th August 2022 12:12:07 pm
 * Author: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Last Modified: Tuesday, 28th February 2023 12:45:34 pm
 * Modified By: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */

 .selectClinics{
    padding: 24px;
}
.drawerSearch{
    margin:36px 36px 5px 36px;
}
.chip{
    margin: 24px 0px 0px 36px;
}

.footer{
    height: 117px;
    float:right;
}

.footerButtons{
    padding: 40px 36px 46px 0px;
}

.noResultsText{
    margin:24px 36px 5px 36px;
}
/*
 * File: AddInsurance.css
 * Project: mint-portal
 * File Created: Monday, 1st August 2022 2:16:05 am
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Friday, 12th August 2022 1:25:58 pm
 * Modified By: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
 .insuranceAddContainer {
    display: flex;
    flex-direction: column;
    width: 76.5vw;
}

.insuranceAddHeader {
    margin: 32px -24px 0px 0px;
}

.insuranceAddBox {
    width: 76.5vw;
    height: 65vh;
    margin: 32px 0px 32px 24px;
    padding-right: 11px;
}

.insuranceAdd {
    margin: 32px 0px 0px 24px;
}

.clinicSection {
    display: flex;
    flex-direction: column;
    margin-top: 32px;
    margin-left: 8px;
    position: inherit;
}

.insuranceNameSection{
    display: flex;
    flex-direction: column;
    width: 40vw;
}
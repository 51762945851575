/*
 * File: AddStaff.css
 * Project: mint-portal
 * File Created: Tuesday, 6th September 2022 2:45:17 pm
 * Author: Jackson Thounaojam (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Wednesday, 7th September 2022 1:40:06 pm
 * Modified By: Jackson Thounaojam (priya.gupta@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
.selected-staff{
    flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  border-radius: 8px;
}



/*
 * File: Videos.css
 * Project: mint-portal
 * File Created: Thrusday, 26th May 2023 07:30:17 pm
 * Author: Rashi Bhatt (rashi.bhatt@mutualmobile.com)
 * -----
 * Last Modified: Thrusday, 26th May 2023 10:30:18 pm
 * Modified By: Rashi Bhatt (rashi.bhatt@mutualmobile.com)
 * -----
 * Copyright 2020 - 2023 Mutual Mobile, Mutual Mobile
 */
 .videosContainer{
    display: flex;
    flex-direction: column;
} 
.videosHeader {
    margin: 18px 0px 0px 8px;
}
  
.videosTitle {
    flex-grow: 1;
    letter-spacing: 0.44px;
}
/*
 * File: LIstDraftClinic.css
 * Project: mint-portal
 * File Created: Tuesday, 23rd August 2022 4:16:38 pm
 * Author: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Last Modified: Wednesday, 24th August 2022 3:37:06 pm
 * Modified By: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
 .containerDraftdiv {
    margin: 28px 0px 24px 0px;
    padding-right: 11px;
    padding-left: 11px;
    width: 76.5vw;
  }
  
  .pagination {
    position: absolute;
    right: 32px;
  }
  
/*
 * File: UpdatePassword.css
 * Project: mint-portal
 * File Created: Friday, 21st October 2022 12:59:18 pm
 * Author: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Last Modified: Friday, 9th December 2022 4:50:34 pm
 * Modified By: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
.updatePasswordHeader {
  margin: 32px 0px 32px 24px;
  width: 76.5vw;
  display: flex;
  flex-direction: column;
}

.update-password-card {
  height: 100%;
}

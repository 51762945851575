/*
 * File: ListIconLibrary.css
 * Project: mint-portal
 * File Created: Tuesday, 16th May 2023 2:35:19 pm
 * Author: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Last Modified: Monday, 10th July 2023 7:24:52 am
 * Modified By: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Copyright 2020 - 2023 Mutual Mobile, Mutual Mobile
 */
.iconContainer {
  margin: 32px 0px 32px 24px;
  padding-right: 11px;
  padding-left: 11px;
  width: 76.5vw;
}

.actionText {
  height: 20px;
  flex-grow: 0;
  font-style: normal;
  letter-spacing: 0.32px;
}

.updateButtonContainer {
  position: relative;
}


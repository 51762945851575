.staffAddContainer {
  display: flex;
  flex-direction: column;
  width: 74.5vw;
}
.staffAddHeader {
  margin: 32px -24px 0px 0px;
}

.staffTitle {
  flex-grow: 1;
  letter-spacing: 0.44px;
}

.staffAddBox {
  display: flex;
  margin: 32px 0px 32px 24px;
  padding-right: 11px;
  width: 76.5vw;
}

.staffAdd {
  margin: 32px 0px 0px 24px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.staffProfile {
  position: relative;
  width: 21%;
}

.profileDescription {
  margin-left: 15px !important;
}
.staffImageClass {
  width: 91.1px !important;
  height: 91.5px !important;
  border-radius: 50px !important;
  margin: 15px 0px 15px 10px !important;
}
.staffIconClass {
  position: absolute;
  width: 32.6px;
  height: 32.8px;
  border-radius: 50px !important;
  top: 100px !important;
  left: 70px;
  padding-left: 7px;
  padding-top: 7px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15);
  cursor: pointer;
}

.staffCameraIcon {
  width: 20px !important;
  height: 18px !important;
}

.profileText {
  display: flex;
  flex-direction: column;
  /* margin-bottom: 5px; */
}

.staffHeading {
  position: relative;
  left: 32px;
  top: 36px;
}

.profileDescription {
  margin-left: 15px !important;
}

.smallCard1 {
  width: 200px;
  height: 260px;
}

.smallCard2 {
  width: 200px;
  height: 220px;
}

.smallCard3 {
  width: 200px;
  height: 174px;
}

.smallCard4 {
  width: 200px;
  height: 165px;
}

.radioButtonClass {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 25px;
}

.radioButtonClass4 {
  display: flex;
  flex-direction: row;
  height: 30px;
  width: 100%;
}

.checkIcon {
  width: 18px !important;
  height: 18px !important;
  margin-right: 8px;
}

.marketingHeading {
  position: absolute;
}

.checkIconRow {
  display: flex;
  margin-top: 2px;
  flex-direction: column;
}

.cardsRowClass {
  display: flex;
  margin-left: 14px;
  column-gap: 24px;
}

#input[type='file'] {
  display: none;
}

.countryCodeParent {
  position: relative;
  display: flex;
  align-items: center;
}

.countryCode {
  position: absolute;
  z-index: 1;
  left: 9px;
}

.namesClass {
  display: flex;
  gap: 8px;
}

.clinicsInput {
  position: relative;
  left: 16px;
}

.helperText {
  position: relative;
  left: 24px;
}

.emailField {
  display: flex;
  flex-direction: column;
}

/*
 * File: ListAppointmentDetails.css
 * Project: mint-portal
 * File Created: Monday, 14th November 2022 2:02:20 pm
 * Author: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Last Modified: Thursday, 16th March 2023 1:28:42 pm
 * Modified By: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
 .status {
    width: 200px;
    height: 31px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 6px 32px;
    opacity: 0.67;
    border-radius: 8px;
  }
  
  .buttonText {
    height: 20px;
    flex-grow: 0;
    font-style: normal;
    letter-spacing: 0.32px;
    cursor: pointer;
  }

  .containerAppointmentDiv {
    margin: 8px 0px 24px 0px;
    padding-right: 11px;
    padding-left: 11px;
    width: 76.5vw;
  }
  
.rewardAddContainer {
  display: flex;
  flex-direction: column;
  width: 76.5vw;
}

.rewardAddHeader {
  margin: 32px -24px 0px 0px;
}

.rewardAddBox {
  width: 76.5vw;
  min-height: 65vh;
  margin: 32px 0px 0px 24px;
  padding-right: 11px;
}

.rewardAdd {
  margin: 32px 0px 18px 24px;
}

.rewardSection {
  display: flex;
  flex-direction: column;
  width: 40vw;
  gap: 40px;
}

.imgError {
  display: flex;
  flex-direction: column;
}

.reward-imgDescription {
  margin-left: 15px !important;
  margin-top: 20px !important;
}

.reward-close-img {
  position: absolute;
  top: -4.5rem;
  right: 3%;
}

.reward-inline-err {
  margin-left: 15px !important;
  margin-top: -30px !important;
}

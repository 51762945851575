.container {
  width: 704px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 56px;
  margin: 0px 188px 222px 368px;
  padding: 64px 56px;
  box-shadow: 0 1px 3px 1px rgba(24, 27, 24, 0.1), 0 1px 2px 0 rgba(24, 27, 24, 0.25);
  position: absolute;
  top: 130px;
}

 .welcome {
  height: 39px;
}

 .welcometext {
  letter-spacing: 0.44px;
}

/*
 * File: ForgotPassword.css
 * Project: mint-portal
 * File Created: Wednesday, 3rd August 2022 9:52:40 pm
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Tuesday, 11th October 2022 5:26:22 pm
 * Modified By: Deepankar Saini (deepankar.saini@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
 .forgotPassword .container {
    width: 704px;
    height: 466px;
    margin: 46px 188px 46px 368px;
    padding: 48px 40px 0px 40px;
    box-shadow: 0 1px 3px 1px rgba(24, 27, 24, 0.1), 0 1px 2px 0 rgba(24, 27, 24, 0.25) !important;
  }
  
.forgotPassword .footer {
  position: absolute;
  bottom: 0%;
  left: 38%;
}

.forgotPassword .submitBtn {
  margin-left: -8px; 
}

.alignButton
{
  left: 8px;
}
  
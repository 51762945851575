/*
 * File: ListClinic.css
 * Project: mint-portal
 * File Created: Thursday, 18th August 2022 1:32:51 pm
 * Author: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Last Modified: Friday, 14th October 2022 1:19:10 am
 * Modified By: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */

.containerClinicDiv {
  margin: 8px 0px 24px -4px;
  padding-right: 11px;
  padding-left: 11px;
  width: 76.5vw;
}
.userName {
  height: 24px;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.pagination {
  position: absolute;
  right: 32px;
}

.activeStatus {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}
.openDentalStatus {
  width: 140px;
  height: 31px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 6px 32px;
  opacity: 0.67;
  border-radius: 8px;
}

/*
 * File: ListBlockouts.css
 * Project: mint-portal
 * File Created: Wednesday, 14th September 2022 1:53:15 am
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Thursday, 15th September 2022 4:15:39 pm
 * Modified By: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */
.blockoutContainer {
  margin: 8px 0px 24px -4px;
  padding-right: 11px;
  padding-left: 11px;
  width: 75.5vw;
}

.blockoutListPagination {
  position: absolute;
  right: 44px;
}

.customStatus {
  width: 122px;
  height: 31px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.67;
  border-radius: 8px;
}
.dsoStatus {
  width: 122px;
  height: 31px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.67;
  border-radius: 8px;
}
